/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
@keyframes skeleton-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
@keyframes skeleton-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
.bp-table-cell {
  display: inline-block;
  height: 20px;
  padding: 0 10px;
  line-height: 20px;
  font-size: 12px;
  transition: color 300ms; }
  .pt-dark .bp-table-cell:not([class*="pt-intent-"]):not(.pt-loading) {
    color: #f5f8fa; }
  .bp-table-cell.pt-intent-primary {
    background-color: rgba(19, 124, 189, 0.1);
    color: #137cbd; }
  .pt-dark .bp-table-cell.pt-intent-primary {
    background: rgba(19, 124, 189, 0.1);
    color: #2b95d6; }
  .bp-table-cell.pt-intent-success {
    background-color: rgba(15, 153, 96, 0.1);
    color: #0f9960; }
  .pt-dark .bp-table-cell.pt-intent-success {
    background: rgba(15, 153, 96, 0.1);
    color: #15b371; }
  .bp-table-cell.pt-intent-warning {
    background-color: rgba(217, 130, 43, 0.1);
    color: #d9822b; }
  .pt-dark .bp-table-cell.pt-intent-warning {
    background: rgba(217, 130, 43, 0.1);
    color: #f29d49; }
  .bp-table-cell.pt-intent-danger {
    background-color: rgba(219, 55, 55, 0.1);
    color: #db3737; }
  .pt-dark .bp-table-cell.pt-intent-danger {
    background: rgba(219, 55, 55, 0.1);
    color: #f55656; }
  .bp-table-editing-enabled .bp-table-cell {
    cursor: text; }
  .bp-table-selection-enabled .bp-table-cell {
    cursor: cell; }
  .bp-table-cell.bp-table-truncated-cell {
    overflow: hidden; }
  .bp-table-cell.pt-large,
  .pt-large .bp-table-cell {
    height: 30px;
    line-height: 30px;
    font-size: 14px; }
  .bp-table-cell.pt-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: transparent; }

.bp-table-cell-interactive {
  z-index: 21; }

.bp-table-striped .bp-table-cell-ledger-even {
  background-color: #ffffff; }

.bp-table-striped .bp-table-cell-ledger-odd {
  background-color: #fafcfd; }

.pt-dark .bp-table-striped .bp-table-cell-ledger-even {
  background-color: #293742; }

.pt-dark .bp-table-striped .bp-table-cell-ledger-odd {
  background-color: #2d3c48; }

.bp-table-editable-name input {
  height: 20px; }

.bp-table-editable-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 10px; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
.bp-table-null {
  color: rgba(92, 112, 128, 0.5); }

.bp-table-truncated-value {
  position: absolute;
  top: 0;
  right: 35px;
  left: 10px;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.bp-table-truncated-format-text {
  position: absolute;
  top: 0;
  right: 10px;
  left: 10px;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.bp-table-truncated-popover-target {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  opacity: 0.3;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 5px;
  text-align: center; }
  .bp-table-truncated-popover-target .pt-icon-standard {
    line-height: 20px; }
  .bp-table-truncated-popover-target.pt-popover-open {
    opacity: 1; }
    .bp-table-truncated-popover-target.pt-popover-open .pt-icon-standard {
      color: #137cbd; }
  .bp-table-truncated-popover-target:hover {
    opacity: 1; }

.bp-table-truncated-popover {
  min-width: 200px;
  max-width: 600px;
  max-height: 300px;
  overflow: auto;
  padding: 10px 10px;
  font-family: monospace; }

.bp-table-popover-whitespace-pre {
  white-space: pre; }

.bp-table-popover-whitespace-normal {
  white-space: normal; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
/*
We use box shadows instead of borders since it makes the size and position
calculations much more well-behaved. This mixin allows us to define the themes
for all borders with minimal duplication. See the bottom of this file.
*/
.bp-table-container {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15); }

.bp-table-menu {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-header {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15); }

.bp-table-cell {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-horizontal-cell-divider {
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
  height: 1px; }

.bp-table-column-headers .bp-table-header {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp-table-column-headers .bp-table-header::before {
    right: 1px;
    bottom: 0; }

.bp-table-row-headers .bp-table-header {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp-table-row-headers .bp-table-header::before {
    right: 0;
    bottom: 1px; }

.bp-table-body .bp-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-body .bp-table-last-in-column {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-body .bp-table-last-in-row.bp-table-last-in-column {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-quadrant-top-left .bp-table-cell.bp-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-quadrant-top-left .bp-table-cell.bp-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-quadrant-top-left .bp-table-cell.bp-table-last-in-column.bp-table-last-in-row {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-row {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }
  .bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-row::before {
    right: 3px;
    bottom: 0; }

.bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-column::before {
    right: 0;
    bottom: 3px; }

.bp-table-quadrant-left .bp-table-cell.bp-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp-table-quadrant-top .bp-table-cell.bp-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.pt-dark .bp-table-container {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-menu {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-header {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-cell {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-horizontal-cell-divider {
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4);
  height: 1px; }

.pt-dark .bp-table-column-headers .bp-table-header {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }
  .pt-dark .bp-table-column-headers .bp-table-header::before {
    right: 1px;
    bottom: 0; }

.pt-dark .bp-table-row-headers .bp-table-header {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }
  .pt-dark .bp-table-row-headers .bp-table-header::before {
    right: 0;
    bottom: 1px; }

.pt-dark .bp-table-body .bp-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-body .bp-table-last-in-column {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-body .bp-table-last-in-row.bp-table-last-in-column {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-quadrant-top-left .bp-table-cell.bp-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-quadrant-top-left .bp-table-cell.bp-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-quadrant-top-left .bp-table-cell.bp-table-last-in-column.bp-table-last-in-row {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-row {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }
  .pt-dark .bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-row::before {
    right: 3px;
    bottom: 0; }

.pt-dark .bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }
  .pt-dark .bp-table-quadrant-top-left .bp-table-header.bp-table-last-in-column::before {
    right: 0;
    bottom: 3px; }

.pt-dark .bp-table-quadrant-left .bp-table-cell.bp-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.pt-dark .bp-table-quadrant-top .bp-table-cell.bp-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
@keyframes skeleton-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
.bp-table-header {
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  text-overflow: ellipsis;
  user-select: none; }
  .bp-table-header::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: ""; }
  .bp-table-header .bp-table-row-name,
  .bp-table-header .bp-table-column-name,
  .bp-table-header .bp-table-header-content {
    position: relative;
    width: 100%; }
  .bp-table-selection-enabled .bp-table-header:hover::before, .bp-table-header.bp-table-header-active::before {
    background-color: #e1e8ed; }
    .pt-dark .bp-table-selection-enabled .bp-table-header:hover::before, .pt-dark .bp-table-header.bp-table-header-active::before {
      background-color: #394b59; }
  .bp-table-header.bp-table-header-selected::before {
    background-image: linear-gradient(90deg, rgba(19, 124, 189, 0.1), rgba(19, 124, 189, 0.1)); }

.bp-table-th-menu-container {
  flex-shrink: 0;
  position: absolute;
  right: 1px;
  opacity: 0;
  text-align: right; }
  .bp-table-header:hover .bp-table-th-menu-container,
  .bp-table-header-active .bp-table-th-menu-container, .bp-table-th-menu-container.bp-table-th-menu-open {
    opacity: 1; }
  .bp-table-interaction-bar .bp-table-th-menu-container {
    line-height: 20px; }

.bp-table-th-menu-container-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 30px;
  pointer-events: none; }
  .bp-table-interaction-bar .bp-table-th-menu-container-background {
    height: 20px; }
  .bp-table-th-menu-open .bp-table-th-menu-container-background {
    background-image: linear-gradient(90deg, rgba(245, 248, 250, 0) 0%, #f5f8fa 50%); }
    .pt-dark .bp-table-th-menu-open .bp-table-th-menu-container-background {
      background-image: linear-gradient(90deg, rgba(48, 64, 77, 0) 0%, #30404d 50%); }
  .bp-table-header:hover .bp-table-th-menu-container-background,
  .bp-table-header-active .bp-table-th-menu-container-background,
  .bp-table-th-menu-open .bp-table-th-menu-container-background {
    background-image: linear-gradient(90deg, rgba(245, 248, 250, 0) 0%, #f5f8fa 50%); }
    .pt-dark .bp-table-header:hover .bp-table-th-menu-container-background, .pt-dark
    .bp-table-header-active .bp-table-th-menu-container-background, .pt-dark
    .bp-table-th-menu-open .bp-table-th-menu-container-background {
      background-image: linear-gradient(90deg, rgba(48, 64, 77, 0) 0%, #30404d 50%); }
  .bp-table-selection-enabled .bp-table-header:hover .bp-table-th-menu-container-background,
  .bp-table-selection-enabled .bp-table-header-active .bp-table-th-menu-container-background {
    background-image: linear-gradient(90deg, rgba(225, 232, 237, 0) 0%, #e1e8ed 50%); }
    .pt-dark .bp-table-selection-enabled .bp-table-header:hover .bp-table-th-menu-container-background, .pt-dark
    .bp-table-selection-enabled .bp-table-header-active .bp-table-th-menu-container-background {
      background-image: linear-gradient(90deg, rgba(57, 75, 89, 0) 0%, #394b59 50%); }
  .bp-table-selection-enabled .bp-table-header.bp-table-header-selected .bp-table-th-menu-container-background {
    background-image: linear-gradient(90deg, rgba(222, 236, 244, 0) 0%, #deecf4 50%); }
    .pt-dark .bp-table-selection-enabled .bp-table-header.bp-table-header-selected .bp-table-th-menu-container-background {
      background-image: linear-gradient(90deg, rgba(45, 70, 88, 0) 0%, #2d4658 50%); }
  .bp-table-selection-enabled .bp-table-header.bp-table-header-selected:hover .bp-table-th-menu-container-background {
    background-image: linear-gradient(90deg, rgba(204, 221, 232, 0) 0%, #ccdde8 50%); }
    .pt-dark .bp-table-selection-enabled .bp-table-header.bp-table-header-selected:hover .bp-table-th-menu-container-background {
      background-image: linear-gradient(90deg, rgba(53, 80, 99, 0) 0%, #355063 50%); }

.bp-table-th-menu {
  cursor: pointer;
  width: 30px;
  height: 30px; }
  .bp-table-interaction-bar .bp-table-th-menu {
    right: 1px;
    width: 20px;
    height: 20px;
    text-align: center; }
    .bp-table-interaction-bar .bp-table-th-menu .pt-icon-standard {
      margin: 2px;
      margin-left: 3px;
      vertical-align: top; }
  .bp-table-th-menu .pt-icon-standard {
    margin-top: 7px;
    margin-right: 7px;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
    background-color: #f5f8fa;
    color: #5c7080; }
    .bp-table-selection-enabled .bp-table-th-menu .pt-icon-standard {
      background-color: #e1e8ed; }
    .bp-table-selection-enabled .bp-table-header.bp-table-header-selected .bp-table-th-menu .pt-icon-standard {
      background-color: linear-gradient(90deg, rgba(222, 236, 244, 0) 0%, #deecf4 50%); }
    .pt-dark .bp-table-th-menu .pt-icon-standard {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
      background-color: #30404d;
      color: #bfccd6; }
    .pt-dark .bp-table-selection-enabled .bp-table-th-menu .pt-icon-standard {
      background-color: #394b59; }
    .pt-dark .bp-table-selection-enabled .bp-table-header.bp-table-header-selected .bp-table-th-menu .pt-icon-standard {
      background-color: linear-gradient(90deg, rgba(45, 70, 88, 0) 0%, #2d4658 50%); }
  .bp-table-th-menu:hover .pt-icon-standard {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #182026; }
    .pt-dark .bp-table-th-menu:hover .pt-icon-standard {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4);
      color: #f5f8fa; }
  .bp-table-th-menu.pt-popover-open .pt-icon-standard {
    box-shadow: none;
    background-color: #137cbd;
    color: #ffffff; }
    .pt-dark .bp-table-th-menu.pt-popover-open .pt-icon-standard {
      box-shadow: none;
      background-color: #137cbd;
      color: #ffffff; }

.bp-table-thead {
  display: block;
  white-space: nowrap; }

.bp-table-column-header-tr {
  display: flex; }
  .bp-table-column-header-tr .bp-table-header {
    flex: 0 0; }

.bp-table-column-headers .bp-table-interaction-bar {
  position: relative;
  height: 20px; }

.bp-table-column-headers .bp-table-header {
  min-height: 30px;
  vertical-align: top;
  line-height: 30px; }

.bp-table-row-headers .bp-table-header {
  min-width: 30px;
  overflow: hidden;
  line-height: 20px; }

.bp-table-column-name-text,
.bp-table-row-name-text {
  flex-grow: 1;
  pointer-events: none; }

.bp-table-truncated-text {
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.bp-table-no-wrap-text {
  white-space: nowrap; }

.bp-table-column-name-text {
  padding: 0 10px; }

.bp-table-editable-name {
  display: block;
  pointer-events: all; }
  .bp-table-editable-name.pt-editable-text::before {
    top: -1px;
    right: -10px;
    bottom: 0;
    left: -11px;
    border-radius: 0; }
  .bp-table-editable-name.pt-editable-text:not(.pt-editable-editing)::before {
    box-shadow: none; }
  .bp-table-editable-name.pt-editable-text.pt-editable-editing::before {
    top: 0px;
    right: -9px;
    bottom: 1px;
    left: -10px;
    cursor: text; }
  .bp-table-editable-name.pt-editable-text.pt-editable-editing.bp-table-editable-text::before {
    right: 1px;
    left: 0px; }
  .bp-table-column-name .bp-table-editable-name.pt-editable-text::before {
    bottom: -1px; }
  .bp-table-column-name .bp-table-editable-name.pt-editable-text.pt-editable-editing::before {
    bottom: 0; }

.bp-table-column-name-text .bp-table-editable-name input {
  height: 30px; }

.bp-table-column-name {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 14px; }

.bp-table-row-name {
  display: block;
  padding: 0 5px;
  text-align: right;
  font-size: 12px; }

.bp-table-header-content {
  white-space: normal;
  font-size: 14px; }
  .bp-table-header-content .is-searchable:not(.is-focused) > .Select-control {
    box-shadow: none;
    background: none; }
  .bp-table-header-content .is-searchable > .Select-control {
    border-radius: 0; }
    .bp-table-header-content .is-searchable > .Select-control .Select-value {
      cursor: pointer; }
  .bp-table-header-content .Select-value {
    right: -1px; }

.bp-table-column-name,
.bp-table-row-name {
  transition: color 300ms; }

.bp-table-header.pt-loading {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .bp-table-header.pt-loading .bp-table-column-name,
  .bp-table-header.pt-loading .bp-table-row-name {
    flex: 1; }
  .bp-table-header.pt-loading .bp-table-column-name-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px; }
    .bp-table-header.pt-loading .bp-table-column-name-text .pt-skeleton {
      height: 8px; }
  .bp-table-header.pt-loading .bp-table-row-name {
    display: flex;
    flex-direction: column;
    justify-content: center; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
.bp-table-selection-enabled.bp-table-column-headers .bp-table-header {
  cursor: s-resize; }
  .bp-table-selection-enabled.bp-table-column-headers .bp-table-header.bp-table-header-reorderable {
    cursor: grab; }
    .bp-table-selection-enabled.bp-table-column-headers .bp-table-header.bp-table-header-reorderable:active {
      cursor: grabbing; }
    .bp-table-selection-enabled.bp-table-column-headers .bp-table-header.bp-table-header-reorderable .bp-table-interaction-bar {
      cursor: grab; }
      .bp-table-selection-enabled.bp-table-column-headers .bp-table-header.bp-table-header-reorderable .bp-table-interaction-bar:active {
        cursor: grabbing; }

.bp-table-selection-enabled.bp-table-row-headers .bp-table-header {
  cursor: e-resize; }
  .bp-table-selection-enabled.bp-table-row-headers .bp-table-header.bp-table-header-reorderable {
    cursor: grab; }
    .bp-table-selection-enabled.bp-table-row-headers .bp-table-header.bp-table-header-reorderable:active {
      cursor: grabbing; }
    .bp-table-selection-enabled.bp-table-row-headers .bp-table-header.bp-table-header-reorderable .bp-table-interaction-bar {
      cursor: grab; }
      .bp-table-selection-enabled.bp-table-row-headers .bp-table-header.bp-table-header-reorderable .bp-table-interaction-bar:active {
        cursor: grabbing; }

.bp-table-selection-enabled.bp-table-menu {
  cursor: se-resize; }

.bp-table-selection-enabled .pt-editable-text::before,
.bp-table-selection-enabled .pt-editable-content {
  cursor: cell; }

.bp-table-column-header-cell.bp-table-has-reorder-handle:not(.bp-table-has-interaction-bar) .bp-table-column-name-text {
  padding-left: 22px; }

.bp-table-column-header-cell.bp-table-has-reorder-handle:not(.bp-table-has-interaction-bar) .bp-table-editable-name::before {
  left: -22px; }

.bp-table-reorder-handle-target {
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 22px;
  color: rgba(92, 112, 128, 0.5); }
  .bp-table-reorder-handle-target:active {
    cursor: grabbing; }
  .bp-table-reorder-handle-target:hover {
    color: #182026; }
  .bp-table-reorder-handle-target:active {
    color: #137cbd; }

.bp-table-resize-handle-target {
  position: absolute;
  opacity: 0;
  z-index: 20;
  user-select: none; }
  .bp-table-resize-handle-target:hover, .bp-table-resize-handle-target.bp-table-dragging {
    opacity: 1; }
  .bp-table-resize-handle-target.bp-table-resize-vertical {
    top: 0;
    right: 0;
    bottom: -1px;
    cursor: ew-resize;
    width: 5px; }
  .bp-table-resize-handle-target.bp-table-resize-horizontal {
    right: -1px;
    bottom: 0;
    left: 0;
    cursor: ns-resize;
    height: 5px; }

.bp-table-resize-handle {
  position: absolute;
  z-index: 20;
  background-color: #137cbd; }
  .bp-table-resize-handle.bp-table-dragging {
    background-color: #137cbd; }

.bp-table-resize-vertical .bp-table-resize-handle {
  top: 0;
  bottom: 0;
  left: 2px;
  width: 3px; }

.bp-table-resize-horizontal .bp-table-resize-handle {
  top: 2px;
  right: 0;
  left: 0;
  height: 3px; }

.bp-table-resize-guides .bp-table-horizontal-guide {
  margin-top: -3px;
  background-color: #137cbd;
  height: 3px; }
  .bp-table-resize-guides .bp-table-horizontal-guide.bp-table-horizontal-guide-flush-top {
    margin-top: 0; }

.bp-table-resize-guides .bp-table-vertical-guide {
  margin-left: -3px;
  background-color: #137cbd;
  width: 3px; }
  .bp-table-resize-guides .bp-table-vertical-guide.bp-table-vertical-guide-flush-left {
    margin-left: 0; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
.bp-table-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  overflow: hidden;
  pointer-events: none; }

.bp-table-overlay {
  position: absolute; }

.bp-table-region {
  border: 1px solid #8a9ba8;
  background-color: rgba(138, 155, 168, 0.1); }

.bp-table-selection-region {
  border: 1px solid #137cbd;
  background-color: rgba(19, 124, 189, 0.1); }
  .bp-table-column-headers .bp-table-selection-region {
    background-color: transparent; }
  .bp-table-row-headers .bp-table-selection-region {
    background-color: transparent; }

.bp-table-focus-region {
  border: 2px solid #137cbd; }

.bp-table-column-headers .bp-table-region {
  border-bottom: none; }

.bp-table-row-headers .bp-table-region {
  border-right: none; }

.bp-table-vertical-guide {
  top: 0;
  bottom: 0; }

.bp-table-horizontal-guide {
  right: 0;
  left: 0; }

.bp-table-reordering-cursor-overlay {
  cursor: grabbing; }
  .bp-table-reordering .bp-table-reordering-cursor-overlay {
    pointer-events: all; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases
*/
/*
Cursors
*/
/*
Z-index layers
*/
.bp-table-quadrant-stack {
  display: flex;
  position: relative;
  height: 100%; }

.bp-table-quadrant {
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f8fa;
  overflow: hidden; }
  .pt-dark .bp-table-quadrant {
    background-color: #30404d; }

.bp-table-quadrant-scroll-container {
  transform: translateZ(0);
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  user-select: none; }
  .bp-table-no-vertical-scroll .bp-table-quadrant-scroll-container {
    overflow-y: hidden; }
  .bp-table-no-horizontal-scroll .bp-table-quadrant-scroll-container {
    overflow-x: hidden; }

.bp-table-quadrant-body-container {
  position: relative; }

.bp-table-quadrant-main {
  position: relative;
  top: auto;
  left: auto;
  z-index: 0;
  width: 100%;
  height: 100%; }
  .bp-table-quadrant-main .bp-table-quadrant-scroll-container {
    width: 100%;
    height: 100%; }
  .bp-table-quadrant-main .bp-table-cell-client {
    background: #ffffff; }

.bp-table-quadrant-top {
  right: 0;
  z-index: 1; }
  .bp-table-quadrant-top .bp-table-quadrant-scroll-container {
    bottom: -20px;
    overflow-y: hidden; }

.bp-table-quadrant-left {
  bottom: 0;
  z-index: 2;
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp-table-quadrant-left .bp-table-quadrant-scroll-container {
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    height: auto;
    overflow-x: hidden; }
  .bp-table-quadrant-left .bp-table-body-virtual-client {
    min-width: 1px; }

.bp-table-quadrant-top-left {
  z-index: 3;
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp-table-quadrant-top-left .bp-table-quadrant-scroll-container {
    right: -20px;
    bottom: -20px;
    overflow-x: hidden;
    overflow-y: hidden; }
  .bp-table-quadrant-top-left .bp-table-body-virtual-client {
    min-width: 1px; }

.bp-table-container {
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  background-color: #f5f8fa;
  max-width: 100%;
  height: 100%;
  min-height: 60px;
  max-height: 100%;
  overflow: hidden; }
  .pt-dark .bp-table-container {
    background-color: #30404d; }
  .bp-table-container .pt-loading {
    color: transparent; }
    .bp-table-container .pt-loading .pt-skeleton {
      opacity: 0;
      height: 5px;
      animation: 300ms linear forwards skeleton-fade-in, 2000ms linear infinite glow;
      animation-delay: 0s, 300ms; }

.bp-table-top-container {
  display: flex;
  flex: 0 0 auto;
  min-height: 0%; }
  .bp-table-container.bp-table-no-rows .bp-table-top-container {
    padding-bottom: 1px; }

.bp-table-bottom-container {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  min-height: 0%;
  color: #182026; }
  .pt-dark .bp-table-bottom-container {
    color: #f5f8fa; }

.bp-table-menu {
  flex: 0 0 auto;
  position: relative;
  z-index: 13;
  background-color: #f5f8fa; }
  .pt-dark .bp-table-menu {
    background-color: #30404d; }

.bp-table-column-headers {
  display: block;
  position: relative;
  z-index: 11;
  background-color: #f5f8fa;
  color: #182026; }
  .pt-dark .bp-table-column-headers {
    background-color: #30404d;
    color: #f5f8fa; }

.bp-table-row-headers {
  flex: 0 0 auto;
  position: relative;
  z-index: 12;
  background-color: #f5f8fa;
  color: #5c7080;
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .pt-dark .bp-table-row-headers {
    background-color: #30404d;
    color: #bfccd6; }

.bp-table-body {
  flex: 1 1 100%;
  position: relative;
  z-index: 10;
  overflow: scroll; }

.bp-table-body-virtual-client {
  position: relative; }

.bp-table-cell-client {
  background: #ffffff; }
  .pt-dark .bp-table-cell-client {
    background: #293742; }

.bp-table-tbody {
  display: block;
  white-space: nowrap; }

.bp-table-cell {
  display: inline-block; }

.bp-table-no-layout {
  display: inline-block;
  position: absolute; }

.bp-table-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow: scroll; }
